




















import { Config } from 'client-website-ts-library/services'
import { Component, Mixins } from 'vue-property-decorator'
import { View } from 'client-website-ts-library/plugins'
import { BlogFilter } from 'client-website-ts-library/filters'
import { WebsiteLevel, BlogType } from 'client-website-ts-library/types'
import ContactForm from '@/components/ContactForm.vue'
import Blogs from '@/components/Blogs.vue'

@Component({
  components: {
    Blogs,
    ContactForm,
  },
})
export default class Blog extends Mixins(View) {
  public filter = new BlogFilter({
    SearchLevel: WebsiteLevel.Office,
    SearchGuid: Config.API.WebsiteId,
    Types: [BlogType.Estate, BlogType.Invest, BlogType.Landlord, BlogType.Market, BlogType.Promotions, BlogType.Social],
  });
}
